import $ from './jquery'
import { isEmpty } from '_u/helper'
import {
    getContextByPath,
    getPropByPath,
    setPropByPath,
    runQueue,
    throttle,
    debounce,
    isPojo,
} from 'es-sharp'

/* utils */
window._ = {
    getContextByPath,
    getPropByPath,
    setPropByPath,
    runQueue,
    throttle,
    debounce,
    isEmpty,
    isPojo,
}

/* form 插件 */
;(function ($) {
    // 构造函数定义
    function Form(element, options) {
        this.$el = $(element)

        this.init(options)
    }

    // 添加原型方法/属性
    $.extend(Form.prototype, {
        // 初始化函数
        init(options) {
            var me = this
            options = options || {}
            me.data = {
                model: {},
                error: {},
                errorMsg: {},
            }
            me.data.rules = options.rules || {} // 校验规则

            // collect
            me.$el.find('[data-prop]').each(function () {
                var key = $(this).data('prop')
                me.data.model[key] = $(this).val() || ''
                me.data.error[key] = false
                me.data.errorMsg[key] = ''
            })

            // listen
            me.$el.on('input', function (e) {
                var $target = $(e.target),
                    prop = $target.data('prop')

                if (prop) {
                    var value = $target.val()
                    setPropByPath(me.data.model, prop, value)
                    me.checkFieldChange(prop, value)
                }
            })
        },
        clean() {
            this.data = {}
            this.$el.off('input')
        },
        // 防抖，避免连续校验
        checkFieldChange: throttle(
            350,
            function (key, value) {
                this.validateField(key, value)
            },
            true
        ),
        // 校验某个域
        validateField(name, val, lazy) {
            var rules = this.data.rules[name] || [],
                msg = '',
                me = this,
                result = true

            return new Promise(function (resolve, reject) {
                runQueue(
                    rules,
                    function (rule, next) {
                        // 必填未通过
                        if (rule.required && isEmpty(val)) {
                            result = false
                            msg = rule.message

                            return next(false)
                        }

                        // 自定义校验器
                        if (rule.validator) {
                            // 延续validator(rule, value, callback, source, options)
                            return rule.validator.call(
                                me,
                                rule,
                                val,
                                function (error) {
                                    if (error) {
                                        result = false
                                        msg = rule.message || error.toString()
                                        next(false)
                                    } else {
                                        next()
                                    }
                                },
                                me.data.model,
                                {}
                            )
                        }

                        // 默认通过
                        return next()
                    },
                    function () {
                        // 更新error、errorMessage
                        me.data.error[name] = !result
                        me.data.errorMsg[name] = msg

                        var change = {
                            name: name,
                            result: result,
                            msg: msg,
                        }

                        if (lazy) {
                            resolve(change)
                        } else {
                            me.render()
                            result ? resolve(change) : reject(msg)
                        }
                    }
                )
            })
        },
        // 校验
        validate(names) {
            var model = this.data.model,
                me = this

            // 校验指定字段"key1,key2,key3..."
            if (names && typeof names === 'string') {
                names = names
                    ? names.split(',').map(function (v) {
                          v.trim()
                      })
                    : []
            } else {
                // 校验所有字段
                names = Object.keys(me.data.rules || {})
            }
            // lazy模式，等所有校验完成后，再得出结论
            return Promise.all(
                names.map(function (name) {
                    return me.validateField(
                        name,
                        getPropByPath(model, name).v,
                        true
                    )
                })
            ).then(function (args) {
                var errors = []

                args.forEach(function (v) {
                    // 更新error、errorMessage
                    me.data.error[v.name] = !v.result
                    me.data.errorMsg[v.name] = v.msg

                    if (!v.result) {
                        errors.push(v.msg)
                    }
                })

                me.render()

                return errors.length
                    ? Promise.reject(errors)
                    : Promise.resolve(args)
            })
        },
        // 渲染
        render() {
            var $errors = this.$el.find('.form-error'),
                errorMsg = this.data.errorMsg

            // 错误信息显示与隐藏
            $errors.each(function () {
                var $v = $(this),
                    name = $v.data('for')

                if (errorMsg[name]) {
                    $v.text(errorMsg[name])
                    $v.show()
                } else {
                    $v.text('')
                    $v.hide()
                }
            })
        },
        setOption(options) {
            this.clean()
            this.init(options)
            this.render()
        },
    })

    // 为jQuery.fn对象添加插件
    $.fn.asForm = function (options) {
        // 返回jquery对象
        return this.each(function () {
            // 判断对象是否绑定插件
            var ins = $(this).data('as-form')
            if (ins instanceof Form === false) {
                ins = new Form(this, options)

                $(this).data('as-form', ins)
            }
        })
    }

    // exports
    $.fn.asForm
})($)
