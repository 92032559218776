import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import camelcase from 'camelcase-web'
import {
    capitalize,
    throttle,
    debounce,
    objMatch,
    objectMerge,
    deepTraverse,
    setUnion,
    listToTree,
    type,
    pathJoin,
    currency,
    qs,
    deepCopy,
    isPojo,
} from 'es-sharp'
import { lsSet, lsGet } from './ls'

// dayjs 扩展
dayjs.extend(customParseFormat)

export {
    capitalize,
    throttle,
    debounce,
    objMatch,
    objectMerge,
    deepTraverse,
    setUnion,
    listToTree,
    type,
    pathJoin,
    currency,
    qs,
    deepCopy,
    dayjs,
    camelcase,
    isPojo,
}

// 为空
export const isEmpty = function (value) {
    return (
        value === '' ||
        value === null ||
        value === undefined ||
        // []
        (Array.isArray(value) && value.length <= 0) ||
        // {}
        (isPojo(value) && Object.keys(value).length <= 0)
    )
}

// 缓存登录凭证
export function setPassport(passport) {
    if (!passport) passport = null

    return lsSet('passport', passport)
}
// 读取登录凭证
export function getPassport(fallback = null) {
    return lsGet('passport', fallback)
}
// 获取租户code
export function getTenant() {
    const passport = getPassport({})

    return passport.tenantCode || ''
}

export function pascalcase(s) {
    return camelcase(s, {
        pascalCase: true,
    })
}

// 四舍五(数字，精度)
export function round(num, acc = 0) {
    var n = num * 1
    if (isNaN(n)) {
        throw new Error(`${num}不是数字，无法四舍五入`)
    }

    return parseFloat(n.toFixed(acc))
}

export const getTarget = () => {
    return Vue.$config.target
}

// 图片压缩 file: File | Blob
export function compressImage(file, options) {
    return import('compressorjs').then(({ default: Compressor }) => {
        if (!file) {
            return Promise.reject('文件不存在')
        }

        return new Promise((resolve, reject) => {
            var _opts = Object.assign({}, options, {
                success(result) {
                    // Bolb转File
                    var newFile = new window.File([result], result.name, {
                        type: result.type,
                        lastModified: result.lastModified,
                    })
                    resolve(newFile)
                },
                error(err) {
                    // @hack
                    err.msg = err.message
                    reject(err)
                },
            })

            new Compressor(file, _opts)
        })
    })
}

// 转大写
export function toUpperCase(s) {
    if (!s) return ''

    return s.toString().toUpperCase()
}

// 转小写
export function toLowerCase(s) {
    if (!s) return ''

    return s.toString().toLowerCase()
}

export function toDateYmd(val) {
    return toDate(val, 'YYYY-MM-DD')
}

// 时间戳或者时间字符转Date
export function toDate(val, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!val) {
        throw new Error('Invalid Date')
    }

    var d,
        t = type(val)

    // 时间戳
    if (t == 'number') {
        d = dayjs(val)
    }

    if (t == 'string') {
        d = dayjs(val, format)
    }

    if (t == 'date') {
        return val
    }

    if (d.toString() == 'Invalid Date') {
        throw new Error('Invalid Date')
    }

    return d.toDate()
}

// 日期格式化为 年-月-日 时:分:秒
export function ymdL(s) {
    if (!s) {
        return ''
    }

    var d

    if (type(s) === 'string') {
        d = dayjs(s, 'YYYY-MM-DD HH:mm:ss')
    } else {
        d = dayjs(s)
    }

    if (d.toString() == 'Invalid Date') {
        throw new Error('Invalid Date')
    }

    return d.format('YYYY-MM-DD HH:mm:ss')
}

// 日期格式化为 年-月-日
export function ymd(s) {
    if (!s) {
        return ''
    }

    var d

    if (type(s) === 'string') {
        d = dayjs(s, 'YYYY-MM-DD')
    } else {
        d = dayjs(s)
    }

    if (d.toString() == 'Invalid Date') {
        throw new Error('Invalid Date')
    }

    return d.format('YYYY-MM-DD')
}

// 日期格式化为 年-月
export function ym(s) {
    if (!s) {
        return ''
    }

    var d = dayjs(s)

    if (d.toString() == 'Invalid Date') {
        throw new Error('Invalid Date')
    }

    return d.format('YYYY-MM')
}

// 解析动态路由
export function parseDynamicUrl(url, params) {
    return url.replace(/:([^\/]+)/g, (part, name) => {
        return params[name]
    })
}

// 获取路由绝对路径
export function getAbsPath(...paths) {
    return `${window.location.origin}${pathJoin(
        process.env.VUE_APP_PUBLICK_PATH,
        ...paths
    )}`
}

// 获取可发布的绝对路由path
export function getAbsRoutePath(...paths) {
    var { pageName, routerMode } = Vue.$config
    var basePath = pathJoin(process.env.VUE_APP_PUBLICK_PATH, pageName),
        host = window.location.origin

    // hash模式
    if (routerMode == 'hash') {
        return host + basePath + '.html#' + pathJoin('/', ...paths)
    }

    // history模式
    return host + pathJoin(basePath, ...paths)
}

// 获取静态资源绝对路径
export function getAbsStaticPath(...paths) {
    var origin = paths[0] || ''

    if (origin.startsWith('http:') || origin.startsWith('https:')) {
        paths = paths.slice(1)
    } else if (process.env.VUE_APP_STATIC_SERVER) {
        origin = process.env.VUE_APP_STATIC_SERVER
    } else {
        // 取location
        return getAbsPath(...paths)
    }

    var [protocol, host] = origin.split('//')

    return `${protocol}//${pathJoin(host, ...paths)}`
}

// 获取微信授权地址
export function getAuthWxUrl({
    scope = 'snsapi_userinfo',
    state = 'STATE',
    name = 'wx-auth'
} = {}) {
    // snsapi_base snsapi_userinfo
    var { appid } = Vue.$config.wx,
        redirectUri = getAbsRoutePath(name)

    // 内部环境利用yuzhi转发
    if (process.env.VUE_APP_RELEASED == '0') {
        redirectUri = `http://wxredirect.yuzhi.cn/?path=${encodeURIComponent(
            redirectUri
        )}`
    }

    var url =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        encodeURIComponent(appid) +
        '&redirect_uri=' +
        encodeURIComponent(redirectUri) +
        '&response_type=code&scope=' +
        encodeURIComponent(scope) +
        '&state=' +
        encodeURIComponent(state) +
        '#wechat_redirect'

    console.log(url)

    return url
}

// 是否存在
function hasOneOf(targetarr, arr) {
    return targetarr.some((_) => arr.indexOf(_) > -1)
}

// 菜单权限校验
function showThisMenuEle(item, access) {
    // 目录、Main不做校验
    if (item.meta && (item.meta.isDirectory || item.meta.resType < 0)) {
        return true
    }

    if (item.meta && item.meta.access && item.meta.access.length) {
        return hasOneOf(item.meta.access, access)
    } else {
        return true
    }
}

// 查询主页
export const getHomeRoute = (routers, homeName = 'home') => {
    let i = -1
    let len = routers.length
    let homeRoute = {}
    while (++i < len) {
        let item = routers[i]
        if (item.children && item.children.length) {
            let res = getHomeRoute(item.children, homeName)
            if (res.name) return res
        } else {
            if (item.name === homeName) homeRoute = item
        }
    }
    return homeRoute
}

// 通过路由列表得到菜单列表
export const getMenuByRouter = (list, access) => {
    let res = []

    list.forEach((item) => {
        if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
            let obj = {
                icon: (item.meta && item.meta.icon) || '',
                name: (item.meta && item.meta.targetName) || item.name,
                meta: item.meta || {},
            }
            // 子级菜单
            if (
                item.children &&
                item.children.length !== 0 &&
                showThisMenuEle(item, access) // 权限判断
            ) {
                obj.children = getMenuByRouter(item.children, access)
            }
            // 支持静态超链接
            if (item.meta && item.meta.href) obj.href = item.meta.href
            // 权限过滤
            if (showThisMenuEle(item, access)) res.push(obj)
        }
    })

    return res
}

// route转tagNavItem
export const getTagFromRoute = (route) => {
    let router = {
            name: route.name,
            title: '',
            path: route.path,
            query: route.query,
            params: route.params,
        },
        meta = {}

    if (route.meta) {
        meta.title = route.meta.title
    }

    if (meta.title && typeof meta.title === 'function') {
        meta.title = meta.title(router)
    }

    router.meta = meta

    return router
}

// 判断2个tag标签是否相等
export const tagEqual = (tag1, tag2) => {
    const params1 = tag1.params || {}
    const params2 = tag2.params || {}
    const query1 = Object.assign({}, tag1.query, { _VTK_: '' })
    const query2 = Object.assign({}, tag2.query, { _VTK_: '' })

    return (
        tag1.name === tag2.name &&
        objMatch(params1, params2) &&
        objMatch(query1, query2)
    )
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
    let newList = [...list]

    // 已存在
    if (newList.findIndex((v) => tagEqual(v, newRoute)) >= 0) {
        return newList
    }

    newList.push(getTagFromRoute(newRoute))

    return newList
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextTag = (list, route) => {
    let res = {}
    if (list.length === 2) {
        res = getHomeRoute(list)
    } else {
        const index = list.findIndex((item) => tagEqual(item, route))

        if (index === list.length - 1) res = list[list.length - 2]
        else res = list[index + 1]
    }

    return getTagFromRoute(res)
}

// 显示menu中字
export const showTitle = (item, vm) => {
    var title = ''
    // i18n
    if (vm.$config.plugins['vue-i18n']) {
        var key = 'menu.' + item.name
        title = vm.$t(key)
    }
    if (title !== key) return title

    return (item.meta && item.meta.title) || item.name
}

// 下载blob流文件
export function downLoadBlob(blob, fileName = 'download') {
    if ('download' in document.createElement('a')) {
        // 非IE下载
        let URL = window.URL || window.webkitURL
        let objectUrl = URL.createObjectURL(blob)
        let elink = document.createElement('a')

        if (typeof elink.download === 'undefined') {
            window.location = objectUrl
        } else {
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = objectUrl
            elink.target = '_blank'
            document.body.appendChild(elink)
            elink.click()

            setTimeout(() => {
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            }, 1000)
        }
    } else if (window.navigator.msSaveBlob) {
        // IE10+下载
        window.navigator.msSaveBlob(blob, fileName)
    } else {
        throw new Error('浏览器不支持Blob下载')
    }
}

// 获取文件预览url
export function getFilePreviewUrl(id = '') {
    return `${Vue.$config.baseURL}api/sys/oss/preview${id ? '?id=' + id : ''}`
}

// 获取文件下载url
export function getFileDownloadUrl(id = '') {
    return `${Vue.$config.baseURL}api/sys/oss/download${id ? '?id=' + id : ''}`
}

// 时差转换为对用户友好的形式(dis是秒)
export const timeDifToFriendlyDesc = function (sec, options = {}) {
    var { accurate = false, fallback = '' } = options
    var result = '',
        stock = sec,
        month,
        week,
        day,
        hour,
        minute,
        second

    if (stock > 18144000) {
        month = Math.floor(stock / 18144000)
        stock = stock - 18144000 * month
        result += month + '个月'

        if (accurate == false) {
            return month + '个月'
        }
    }

    if (stock > 604800) {
        week = Math.floor(stock / 604800)
        stock = stock - 604800 * week
        result += week + '周'

        if (accurate == false) {
            return week + '周'
        }
    }

    if (stock > 86400) {
        day = Math.floor(stock / 86400)
        stock = stock - 86400 * day
        result += day + '天'

        if (accurate == false) {
            return day + '天'
        }
    }

    if (stock > 3600) {
        hour = Math.floor(stock / 3600)
        stock = stock - 3600 * hour
        result += hour + '时'

        if (accurate == false) {
            return hour + '时'
        }
    }

    if (stock > 60) {
        minute = Math.floor(stock / 60)
        stock = stock - 60 * minute
        result += minute + '分'

        if (accurate == false) {
            return minute + '分'
        }
    }

    if (sec > 0) {
        second = stock
        result += Math.floor(stock) + '秒'

        if (accurate == false) {
            return second + '秒'
        }
    }

    return result ? result : fallback
}

// 获取oss图片url
function getUrlCompressred(file) {
    if (file.thumbNails) {
        return file.path + '.' + file.thumbNails + '.png'
    }

    return file.path
}

//  compressred true-缩略图/false-原图
export function getPublicImgUrls(s, compressred = true) {
    if (!s || s == '0') {
        return []
    }

    var files

    switch (type(s)) {
        case 'object':
            files = [s]
            break
        case 'array':
            files = s
            break
        case 'string':
            files = JSON.safeParse(s) || s.split(',').map((id) => ({ id }))
            break
        default:
            files = []
    }

    return files.map((v) =>
        v.path
            ? getAbsStaticPath(compressred ? getUrlCompressred(v) : v.path)
            : getFilePreviewUrl(v.id)
    )
}

// 从附件中获取首图
export function getCoverImg(attachments, compressred = true) {
    var urls = getPublicImgUrls(attachments, compressred)

    return urls.length ? urls[0] : ''
}

// arrary.groupBy
export function groupBy(arr, iteratee) {
    if (typeof iteratee == 'string') {
        var _id = iteratee

        iteratee = function (e) {
            return e[_id]
        }
    }

    if (typeof iteratee != 'function') {
        throw new TypeError(
            'groupBy iteratee`s type must be String or Functiom'
        )
    }

    var groups = {},
        key = ''

    arr.forEach((v, i) => {
        key = iteratee(v, i)
        groups[key] ? groups[key].push(v) : (groups[key] = [v])
    })

    return groups
}

// 获取请求headers
export function getCustomHeaders(url = '') {
    var headers = {
            tenantCode: getTenant(),
        },
        axiosConfig = Vue.$config.axios,
        passport = getPassport() || {}

    // 令牌
    if (process.env.VUE_APP_IDENTITY_AUTH == 'token') {
        headers['Authorization'] = passport.token || ''
    }

    // 业务headers
    Object.assign(headers, axiosConfig.headers || {})

    return headers
}

// 预览 url /oss/20200910/1303948041413562369.png
export function officePreview(path) {
    // prettier-ignore
    var url = `https://ow365.cn/?i=${process.env.VUE_APP_OFFICE365_I}&furl=${getAbsStaticPath(path)}`
    // debug
    if (process.env.VUE_APP_RELEASED == '0') {
        console.log(url)
    }
    return url
}

// 金额由数字转为大写汉字
function shiftRight(number, digit) {
    digit = parseInt(digit, 10)
    const value = number.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? +value[1] + digit : digit))
}

function shiftLeft(number, digit) {
    digit = parseInt(digit, 10)
    const value = number.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? +value[1] - digit : -digit))
}

export function upDigit(n) {
    const fraction = ['角', '分']
    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟'],
    ]
    const head = n < 0 ? '（负数）' : ''
    n = Math.abs(n)
    let s = ''
    for (let i = 0; i < fraction.length; ++i) {
        s += (
            digit[Math.floor(shiftRight(n, 1 + i)) % 10] + fraction[i]
        ).replace(/零./, '')
    }
    s = s || '整'
    n = Math.floor(n)
    for (let i = 0; i < unit[0].length && n > 0; ++i) {
        let p = ''
        for (let j = 0; j < unit[1].length && n > 0; ++j) {
            p = digit[n % 10] + unit[1][j] + p
            n = Math.floor(shiftLeft(n, 1))
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
    }
    return (
        head +
        s
            .replace(/(零.)*零元/, '元')
            .replace(/(零.)+/g, '零')
            .replace(/^整$/, '零元整')
    )
}

// 单位：分
export function cent(num, rate = 100) {
    if (isNaN(num)) {
        return 0
    }

    return parseInt((num * rate).toFixed(0))
}

// 单位：元
export const yuan = (v, rate = 100) => {
    if (type(v) === 'number') {
        return parseFloat((v / rate).toFixed(2))
    } else if (!isNaN(v)) {
        return parseFloat((parseInt(v) / rate).toFixed(2))
    }
}
