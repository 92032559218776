import Vue from 'vue'

// 获取storage命名空间
function ns() {
    return Vue.$config.namespace.storage || Vue.$config.pageName
}

export function lsSet(key, value) {
    // console.log(`${key} = ${JSON.stringify(value)}`)
    localStorage.setItem(ns() + '::' + key, JSON.stringify(value))
}

export function lsGet(key, fallBack = null) {
    var s = localStorage.getItem(ns() + '::' + key)
    try {
        s = JSON.parse(s)
    } catch (e) {
        s = null
    }

    return s === null ? fallBack : s
}

export function lsGlobalSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

export function lsGlobalGet(key, fallBack = null) {
    var s = localStorage.getItem(key)
    try {
        s = JSON.parse(s)
    } catch (e) {
        s = null
    }

    return s === null ? fallBack : s
}

export function getRefer() {
    return lsGet('refer', '/')
}

export function setRefer(refer) {
    return lsSet('refer', refer)
}
