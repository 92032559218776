import './css/common.less'
import './css/component.less'
import './css/index.less'

import $ from './js/jquery'
import './js/component.js'

$(function () {

    // 版权动态时间
    $('.bottom-cpr-time').text(new Date().getFullYear())


    var $reg = $('#reg').asForm({
        rules: {
            name: [{ required: true, message: '姓名必填' }],
            mobile: [{ required: true, message: '手机号必填' }],
            assetsHouseCount: [{ required: true, message: '房源必填' }],
        },
    })

    $reg.on('submit', function (e) {
        var form = $reg.data('as-form')

        // 阻止默认跳转
        e.preventDefault()

        form.validate().then(function () {
            $.post(
                process.env.VUE_APP_SERVER +
                    'api/customer/intention/registerApply',
                form.data.model
            ).then((res) => {
                // 失败
                if (res && res.code != '200') {
                    window.alert(res.msg || '申请失败，请稍后再试')
                }
                // 成功
                else {
                    window.alert(res.msg)
                    location.reload()
                }
            })
        })
    })
})
